<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- Titel en logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-btn
          to="{ name : 'plugin-login-finish', params : { id : localStorage.getItem('accessToken') } }"
          color="primary"
          block
        >
          Fake login for testing
        </v-btn>

        <router-link
          :to="{ name : 'plugin-login-finish', params : { id : jwt_token } }"
          class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
        >
          <span><b>o</b></span>
        </router-link>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig';

// Icons
import {
mdiEyeOffOutline, mdiEyeOutline
} from '@mdi/js';

// Axios

export default {
  data: () => ({
    // themeConfig
    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,

    jwt_token: localStorage.getItem('accessToken'),

    // Icons
    icons: {
      mdiEyeOffOutline,
      mdiEyeOutline,
    },
  }),

  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
